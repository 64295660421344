<template>
    <div>
        <b-card no-body class="app-card-profile">   
                
                <b-row class="app-local-height">
                    <b-col start>
                        <div class="app-card-profile-title">
                            {{trans('actions',5)}}
                        </div>
                    </b-col>                     
                   
                    <b-col cols="auto" end class="d-flex align-items-center">

                        <div style="min-width:24px; max-width:24px; min-height: 26px">  
                            
                            <b-link v-if="!loadingList" style="color: #98A2B3; cursor:pointer; outline:none !important;" @click="getList">
                                <b-icon icon="arrow-repeat" style="width:22px; height:22px" />
                            </b-link>

                            <b-spinner v-else style="width:19px; height:19px; margin-left:3px; margin-top:5px" small></b-spinner>       
                        </div>
                    </b-col>
                </b-row>
                 
                <b-row class="mb-2 mt-2">
                    <b-col md="12" xl="12">                   
                        <b-input-group :disabled="( user_role > 2 && !profile.can_user_edit && profile.can_user_edit != undefined)" class="app-input-group app-input-group-currency" :class="{'app-local-issue-group-disabled': ( user_role > 2 && !profile.can_user_edit && profile.can_user_edit != undefined)}">
                            <b-input-group-prepend  class="d-flex align-items-center app-input-currency-icon app-local-issue-btn" :class="{'app-local-issue-btn-filled': newIssueTittle != null && newIssueTittle.length != ''}"> 
                                <b-button variant="transparent" class="app-local-issue-button" @click="addIssue()" :disabled="user_role > 2 && !profile.can_user_edit && profile.can_user_edit != undefined">
                                    <b-icon icon="plus"  style="width:24px; height:24px; padding:0px; margin:0px; color: #174066"></b-icon>                                                               
                                </b-button>
                            </b-input-group-prepend>
                            <b-form-input class="pl-2 app-input-currency app-local-issue-input" :class="{'app-input-filled': newIssueTittle != null && newIssueTittle.length != '', 'app-local-issue-input-disabled': ( user_role > 2 && !profile.can_user_edit && profile.can_user_edit != undefined)}" :disabled=" ( user_role > 2 && !profile.can_user_edit && profile.can_user_edit != undefined)"  placeholder="Voeg een actie toe" v-model="newIssueTittle" v-on:keyup.enter="addIssueQuick" />
                        </b-input-group>
                    </b-col>
                </b-row>

                <b-overlay :show="loadingList" :opacity="0.8">
                    <b-list-group flush>
                        <b-list-group-item class="app-checklist-item" v-for="item in this.profile.issues.list" :key="item.id">
                            <b-overlay :show="loadingDone === item.id" :opacity="0.8">
                                <b-overlay :show="item.status == 1" :opacity="0.8" v-bind:class="{ textthrough: item.status == 1 }">
                                    <template #overlay><div> </div></template>
                                    
                                    <b-row align-v="center">
                                        <b-col md="auto" class="pr-0">
                                            <b-form-checkbox v-model="item.status" class="custom-control-primary" value="1" unchecked-value="0" size="lg" @change="setAsDone(item.id)" :disabled="user_role > 2 && !profile.can_user_edit && profile.can_user_edit != undefined && !item.can_user_edit"/>                                    
                                        </b-col>
                                        <b-col class="ml-1 pl-0 app-cursor-pointer" @click="((user_role < 3 || profile.can_user_edit || item.can_user_edit) && profile.can_user_edit != undefined) ? changeIssue(item) : null">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <b-link >
                                                    <h4 class="mb-0 text-sm app-checklist-title" >{{item.title}}</h4>
                                                    <span class="app-checklist-span">{{ item.event_date }} {{item.location_name}}</span><br />
                                                    <span class="app-local-entry-date">{{item.entry_date}}</span>
                                                </b-link>
                                            </div>
                                        </b-col>
                                        <b-col md="auto" class="app-cursor-pointer">
                                            <b-icon :id="'popover' + item.id" tabindex="0" @click="showDescription(item.description, item.id)" v-if="item.description != null" icon="text-paragraph" class="bi bi-text-paragraph mr-2" style="outline:none !important"/>
                                            <span v-if="item.id_user != null" class="avatar avatar-xs rounded-circle">{{(item.shortcut_name)}}</span>
                                        </b-col>
                                        <b-popover v-if="item.description != null" :target="'popover' + item.id" ref="popover" no-fade placement="auto" triggers="focus">
                                            <span class="font-weight-bolder">{{popoverDescription}}</span>
                                        </b-popover>
                                    </b-row>

                                </b-overlay>
                            </b-overlay>
                        </b-list-group-item>
                    </b-list-group>
                    <template #overlay>
                        <div></div>
                    </template>
                </b-overlay>

        </b-card>
        <issues-add v-if="mIssueAdd.show" :show.sync="mIssueAdd.show" :result.sync="mIssueAdd.result" :args.sync="mIssueAdd.args" :return.sync="profile"/>
        <issues-change v-if="mIssueChange.show" :show.sync="mIssueChange.show" :result.sync="mIssueChange.result" :args="mIssueChange.args" :return.sync="profile"/>
    </div>
</template>
<script>

import axios from "@axios";
import IssuesAdd from './IssuesAdd.vue';
import IssuesChange from './IssuesChange.vue';

export default {
    components: {
        IssuesAdd,
        IssuesChange
    },

    props:["profile", "type"],

    watch: {
        newIssueTittle: {
            handler: function() {             
                 if(this.newIssueTittle.length == 1){
                     this.newIssueTittle = this.newIssueTittle.toUpperCase();
                 }   
            },
            
            deep: true,
        },

        "mIssueAdd.result": function(val) {
            if (val === 1) {
                this.newIssueTittle = "";
            }
        },
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', false) }, 
        },
    },

    data(){
        return{

            newIssueTittle: "",
            loadingList: false,
            loadingDone: 0,
            mIssueAdd: { show: false, args: {title: null, action_for: null, id:0, list_type:"", id_office_data_location: 0, location_name: null }, result: 0, return: null },
            mIssueChange: { show: false, args: {
                                                id_issue_data_main: null,
                                                action_for: null,
                                                title: null,
                                                description: null,
                                                status: null,
                                                event_date: null,
                                                id_user: 0,
                                                user_name: null,
                                                id_office_data_location: 0,
                                                location_name: null


            }, result: 0, return: null },
            popoverDescription:"",
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    methods:{     
        getList: function() {
            this.loadingList = true;
            axios
                .post("issue/getProfileIssuesList", {
                    id: this.profile.id,
                    list_type: this.profile.type
                })
                .then(res => {
                    this.cProfile.issues.list = res.data;
                })
                .catch(error => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {                  
                    this.loadingList = false;
                });
        },
        
        addIssue() {           
            this.mIssueAdd.args.title = this.newIssueTittle;
            if(this.type == 'person'){
                this.mIssueAdd.args.action_for = this.profile.person_data.profile_name;
            }else if(this.type == 'client'){
                this.mIssueAdd.args.action_for = this.profile.client_data.profile_name;
            }else if(this.type == 'location'){
                this.mIssueAdd.args.action_for = this.profile.location_data.profile_name;
            }else if(this.type == 'department'){
                this.mIssueAdd.args.action_for = this.profile.department_data.profile_name;
            }
            this.mIssueAdd.args.id = this.profile.id;
            this.mIssueAdd.args.list_type = this.profile.type;
            this.mIssueAdd.args.id_office_data_location = this.profile.location.id;
            this.mIssueAdd.args.location_name = this.profile.location.name;
            this.mIssueAdd.result = 0;
            this.mIssueAdd.show = true;
        },

         addIssueQuick() {

            if (this.newIssueTittle.trim().length > 0) {
                
                    this.mIssueAdd.args.title = this.newIssueTittle;
                    if(this.type == 'person'){
                        this.mIssueAdd.args.action_for = this.profile.person_data.profile_name;
                    }else if(this.type == 'client'){
                        this.mIssueAdd.args.action_for = this.profile.client_data.profile_name;
                    }else if(this.type == 'location'){
                        this.mIssueAdd.args.action_for = this.profile.location_data.profile_name;
                    }else if(this.type == 'department'){
                        this.mIssueAdd.args.action_for = this.profile.department_data.profile_name;
                    }
                    this.mIssueAdd.args.id = this.profile.id;
                    this.mIssueAdd.args.list_type = this.profile.type;
                    this.mIssueAdd.args.id_office_data_location = this.profile.location.id;
                    this.mIssueAdd.args.location_name = this.profile.location.name;
                    this.mIssueAdd.result = 0;
                    this.mIssueAdd.show = true;

            } 
            
        },

        changeIssue(item){
            this.mIssueChange.args.id_issue_data_main = item.id;
            if(this.type == 'person'){
                this.mIssueChange.args.action_for = this.profile.person_data.profile_name;
            }else if(this.type == 'client'){
                this.mIssueChange.args.action_for = this.profile.client_data.profile_name;
            }else if(this.type == 'location'){
                this.mIssueChange.args.action_for = this.profile.location_data.profile_name;
            }else if(this.type == 'department'){
                this.mIssueChange.args.action_for = this.profile.department_data.profile_name;
            }            
            this.mIssueChange.args.title = item.title;
            this.mIssueChange.args.description = item.description;
            this.mIssueChange.args.status = item.status;
            this.mIssueChange.args.event_date = item.event_date;
            this.mIssueChange.args.user_name = item.user_name;
            this.mIssueChange.args.id_user = item.id_user;
            this.mIssueChange.args.id = this.profile.id;
            this.mIssueChange.args.list_type = this.profile.type;
            this.mIssueChange.args.id_office_data_location = item.id_office_data_location;
            this.mIssueChange.args.location_name = item.location_name;
            this.mIssueChange.show = true; 
        },

        setAsDone(id_issue_data_main){
            this.loadingDone = id_issue_data_main;
            this.loadingList = true;
            
            axios
                .post("issue/setAsDone", {
                    id_issue_data_main: id_issue_data_main,
                    id: this.profile.id,
                    list_type: this.profile.type,
                })
                .then((res) => {
                    this.cProfile.issues.list = res.data;
                    this.loadingDone = 0;
                    this.loadingList = false;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);                   
                });

        },

        showDescription(description, id) {
            this.popoverDescription = description;
            this.$root.$emit("bv::show::popover", 'popover' + id);
        },

         
           
    }

};
</script>

<style>
.app-checklist-title{
    color: #174066;
    font-weight: 100;
}

.test parent:hover {
    background: yellow;
} 
.popover {
    max-width: 360px !important;
    text-align: justify !important;
}  

.app-local-issue-btn{
    margin: 0 !important;
    padding: 0 !important;
    width: 46px;
    height: 46px;
}

.app-local-issue-btn:hover{
    background-color: #EDF7FB
}

.app-local-issue-button{
    width: 46px;
    height: 46px;
    padding: 6px 10px 6px;
}

.app-local-issue-input{
    transition: none !important;
}

.app-local-issue-input::placeholder{
    color: #174066;
}

.app-local-issue-input:focus-within, .app-local-issue-btn-filled{
    border-color: #0D7BB3 !important;
}

.app-local-issue-group-disabled:hover > .app-local-issue-input, .app-local-issue-group-disabled:hover > .app-local-issue-btn, .app-local-issue-group-disabled:hover{
    border-color: #D0D5DD  !important;
    box-shadow: none !important;
}

.app-local-issue-group-disabled > .app-local-issue-btn, .app-local-issue-group-disabled > .app-local-issue-input{
    background-color: #f8f8f8 !important;
}
.app-local-entry-date{
    font-size: 11px;
}

.app-local-entry-date:hover{
    color: #174066 !important;
    
}

a:hover .app-local-entry-date{
    color: #174066 !important;
}
</style>