<template>
    <b-modal ref="modal-edit" v-model="computedShow" no-close-on-backdrop :title="trans('n-edit-note',204)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
                <app-memo id="textarea-default" rows="5" v-model="cArgs.description" :disabled="loading === 1" />
            </b-form>
        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                <span>{{trans('save',175)}}</span>
            </app-button>
        </template>
        </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {

    components: {        
        ValidationObserver
    },

    props: ["show", "args", "return", "result"],

    computed: {
        computedShow: {
        get() { return this.show },
        set() { this.$emit('update:show', false) }, 
        },
        cReturn: {
        get() { return this.return },
        set() { this.$emit('update:return', false) }
        },
        cArgs: {
        get() { return this.args },
        set() { this.$emit('update:args', false) }
        }

    },
    
    data() {
        return {
            loading:false,
        };
    },

    methods: {
         change () {
            this.$refs.form.validate().then((result) => { 

                if (!result) {
                    return false;
                }else{
                    this.loading = true;
                    axios
                        .post("notes/change", {
                            
                            id_note_data_main: this.cArgs.id_note_data_main,
                            description: this.cArgs.description,
                            id: this.cArgs.id,
                            list_type: this.cArgs.list_type,
                        })
                        .then((res) => {                    
                            this.$emit("update:result", 1);
                            this.$emit("update:show", false);                            
                            this.cReturn.notes.list = res.data
                        })
                        .catch((error) => {
                            console.error(error);
                        })
                        .finally(() => {                    
                            this.loading = false;
                        });
                }

            });
        },

        closeModal() {
            this.$emit("update:show", false);
        },
    }
}  

</script>