<template>
    <div>
        <b-card no-body class="app-card-profile">            
                <b-row class="app-local-height">
                    <b-col start>
                        <div class="app-card-profile-title">
                            {{trans('n-notes',204)}}
                        </div>
                    </b-col>                    
                   
                    <b-col cols="auto" end class="d-flex align-items-center">

                        <div style="min-width:24px; max-width:24px; min-height: 26px">  
                            
                            <b-link v-if="!loadingList" style="color: #98A2B3; cursor:pointer; outline:none !important;" @click="getList">
                                <b-icon icon="arrow-repeat" style="width:22px; height:22px" />
                            </b-link>

                            <b-spinner v-else style="width:19px; height:19px; margin-left:3px; margin-top:5px" small></b-spinner>       
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mt-2" cols="12">
                         <app-memo id="textarea-default" :placeholder="profile.type == 'person_profile'? trans('n-leave-note-here-longer',204) : trans('n-leave-note-here',204)" rows="5" v-model="newDescription" :disabled="loadingAdd || ( user_role > 2 && !profile.can_user_edit && profile.can_user_edit != undefined)" />
                    </b-col>
                    <b-col cols="12" class="mt-4">
                        <app-button type="secondary" pill class="add-note-button" v-on:click="addNote()" :disabled="loadingAdd || ( user_role > 2 && !profile.can_user_edit && profile.can_user_edit != undefined)" :loading="loadingAdd">Voeg notitie toe</app-button>
                    </b-col>
                </b-row>
                
                
                <b-row class="mt-1">
                    <b-col md="12" xl="12">
                    
                    <b-list-group flush>
                        <b-list-group-item class=" flex-column align-items-start app-notes-list" v-for="(item, index) in this.profile.notes.list" :key="index">
                            <b-overlay :show="loadingDelete === item.id || loadingPin === item.id" :opacity="0.0">
                                <template #overlay><div> </div></template>
                                <div class="d-flex w-100 justify-content-between">
                                    <div>
                                        <div class="d-flex w-100 align-items-center">
                                            <span class="avatar avatar-xs rounded-circle">{{item.shortcut_name}}</span>
                                            <h4 class="mb-1 ml-3 app-notes-title">{{ item.user_name }}</h4>
                                        </div>
                                    </div>
                                    <b-link v-if="item.pin > 0" @click="unpinNote(item.id)">
                                        <b-icon icon="pin-angle-fill" style="width: 20px; height: 20px;"></b-icon>
                                    </b-link>
                                    <b-link v-else @click="pinNote(item.id)">
                                        <b-icon icon="pin-angle" style="width: 20px; height: 20px;"></b-icon>
                                    </b-link>
                                </div>
                                <p class="text-sm mt-3 mb-0 app-notes-description">{{ item.description }}</p>
                                <p class="text-sm mt-2 mb-0 app-notes-span"><span>{{ setFormatDate(item.entry_date) }} | </span> 
                                    <span class="app-text-link" @click="changeNote(item)" v-if="((user_role < 3 || profile.can_user_edit) && profile.can_user_edit != undefined)">{{trans('change',175)}}</span> <span v-if="((user_role < 3 || profile.can_user_edit) && profile.can_user_edit != undefined)"> | </span>
                                    <span class="app-text-link" @click="deleteNote(item.id)" v-if="((user_role < 3 || profile.can_user_edit) && profile.can_user_edit != undefined)">{{trans('remove',175)}}</span>
                                </p>
                            </b-overlay>
                        </b-list-group-item>
                    </b-list-group>
                    </b-col>
                </b-row>
             <notes-change v-if="mChangeNote.show"  :show.sync="mChangeNote.show" :result.sync="mChangeNote.result" :args.sync="mChangeNote.args" :return.sync="profile" />
        </b-card>
    </div>
</template>
<script>

// #ID _06-01.01.0000 - DDA 2022-01-13
 
import axios from "@axios";
import NotesChange from "./NotesChange.vue";
import AppAlerts from '@core/scripts/AppAlerts';
import moment from "moment";

export default {
    components: {
        NotesChange
    },

    props:["profile"],

    watch: {
        
        "profile.notes.refresh": {
            handler: function(val) {
                if (val > 0) {
                    this.getList();
                }                
            },
        },
        
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', false) }
        }
    },


    
    data() {
        return {     
            loadingList: false,
            loadingAdd : false,
            loadingPin : 0,
            loadingDelete : 0,
            newDescription : "",
            mChangeNote: { show: false, args: { id_note_data_main: null, description: null, id: null , list_type: null }, result: 0, return: "profile" },
            totalRows:0,
            alertClass: new AppAlerts(),
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,

        };
    },

    methods: {

        getList: function() {
             this.loadingList = true;
            axios
                .post("notes/getList", {
                    id: this.profile.id,
                    list_type: this.profile.type,
                })
                .then(res => {
                    this.cProfile.notes.list = res.data;
                })
                .catch(error => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {                  
                    this.loadingList = false;
                });;
        },

        addNote() {
            if (this.newDescription.length > 0) {
                this.loadingAdd = true;
                axios
                    .post("notes/add", {
                        id: this.profile.id,
                        description: this.newDescription,
                        list_type: this.profile.type,
                    })
                    .then((res) => {
                        this.newDescription = "";
                        this.cProfile.notes.list = res.data;
                        this.loadingAdd = false;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                        this.loadingAdd = false;
                    });
            }
                
        },

        changeNote: function(item) {
            this.mChangeNote.args.id_note_data_main = item.id;
            this.mChangeNote.args.list_type = this.profile.type;
            this.mChangeNote.args.description = item.description;
            this.mChangeNote.args.id = this.profile.id;
            this.mChangeNote.show = true;
        },

        deleteNote: function( idNoteDataMain) {
            this.alertClass.openAlertConfirmation(this.trans('confirm-message',180)).then(res => {
                if(res == true){
                    this.loadingDelete = idNoteDataMain;
                    this.loadingList = true;
                    axios
                    .post("notes/delete", {                        
                        id_note_data_main: idNoteDataMain,
                        id: this.profile.id,
                        list_type: this.profile.type,
                    })
                    .then((res) => {     
                        this.cProfile.notes.list = res.data;
                    })
                    .catch((error) => {
                        console.error(error);
                        this.loadingDelete = 0;
                        this.loadingList = false;
                    })
                    .finally(() => {
                        this.loadingDelete = 0;
                        this.loadingList = false;
                    });
                }     
            });  
        },

        pinNote(idNoteDataMain){
            this.loadingPin = idNoteDataMain;
            this.loadingList = true;
            axios
                .post("notes/pin", {
                    id: this.profile.id,
                    id_note_data_main: idNoteDataMain,
                    list_type: this.profile.type,
                })
                .then((res) => {
                    this.cProfile.notes.list = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                    this.loadingList = false;
                    this.loadingPin = 0;
                })
                .finally(() => {
                    this.loadingList = false;
                    this.loadingPin = 0;
                });
        },

        unpinNote(idNoteDataMain){
            this.loadingPin = idNoteDataMain;
            this.loadingList = true;
            axios
                .post("notes/unpin", {
                    id: this.profile.id,
                    id_note_data_main: idNoteDataMain,
                    list_type: this.profile.type,
                })
                .then((res) => {
                    this.cProfile.notes.list = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                    this.loadingList = false;
                    this.loadingPin = 0;
                })
                .finally(() => {
                    this.loadingList = false;
                    this.loadingPin = 0;
                });
        },

        setFormatDate(eventDate) {
            moment.locale('nl')
            return moment(eventDate).calendar(); 
        }
    },
};
</script>

<style>
    .add-note-button{
        width: 100%;
        height: 50px;
    }

    .app-notes-title{
        color: #174066;
    }

    .app-notes-description{
        color: #174066;
    }

    .app-notes-span{
        color: #525f7f;
    }
    .app-local-height{
        height:20px;
    }
</style>